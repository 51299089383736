.RRSSbutton:visited, .RRSSbutton:active, .RRSSbutton:link{
	color: inherit;
}

.RRSSbutton {
	color: inherit;
	text-decoration: none;	
	transition: 0.1s;
}

.RRSSbutton:hover {
	color: rgb(255, 235, 205);
	transform: scale(1.05);
}

.RRSSbutton > svg{
	filter: drop-shadow(5px 5px 5px rgba(0, 0, 0));
	filter: drop-shadow(1px 1px 1px rgb(255, 255, 255));
}
	

	
.player-wrapper {
	position: relative;
	padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
	width: 100%;
  }

  .react-player {
	position: absolute;
	top: 0;

  }

@media only screen and (min-width: 768px) {
	.show-in-desktop {
		display:block;
	}
	.show-in-mobile {
		display:none;
	}
	.RRSScontainer{
		max-width: 50%;
	}
}

	

@media only screen and (max-width: 767px){
	.show-in-mobile {
		display:block;
	}
	.show-in-desktop {
		display:none;
		}
	.RRSScontainer{
		max-width: 70%;
	}
}



.background {
	position: relative;
	/* The image used */
	/* Full height */
	height: 100%;  
	/* Center and scale the image nicely */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
	background-color:rgba(255, 255, 255, 0.5);
	background-image: url("../_img/map-267241.jpg");
	background-position: center;
	background-repeat: repeat;
	background-size: cover;
	box-shadow:inset 0 0 0 2000px rgba(255, 255, 255, 0.15);
}
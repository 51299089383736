.noDecorLink{
	color: inherit;
	text-decoration: none;
	text-shadow: 1px 1px grey;
}

.popUpLink{
	transition: 0.05s;
}
.popUpLink:hover {
	transform: scale(1.05);
}

.submenuLink{
	padding-right: 8px;
}

.show-in-desktop, .show-in-mobile  {
	display:none;
}

@media only screen and (min-width: 768px) {
	.show-in-desktop {
		display:block;
	}
}

@media only screen and (max-width: 767px){
	.show-in-mobile {
		display:block;
	}
}
